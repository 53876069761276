import { createRouter, createWebHashHistory } from 'vue-router'
import { isPhone } from '@/utils/system/systemUtils'
import { ElMessage } from 'element-plus'

// PC路由
const routePC = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/aiGallery/transferPage.vue'),
    redirect: '/home/galleryList',
    children: [
      {
        path: 'galleryList',
        name: 'galleryList',
        component: () => import('../views/aiGallery/galleryList.vue'),
        meta: {
          page: 'HOME',
          title: '亦心Ai'
        }
      },
      {
        path: 'artGalleryList',
        name: 'artGalleryList',
        component: () => import('../views/aiGallery/artGalleryList.vue'),
        meta: {
          page: 'COMMUNITY',
          title: '亦心Ai'
        }
      },
      {
        path: 'prompt',
        name: 'prompt',
        component: () => import('@/views/aiGallery/promptWords.vue'),
        meta: {
          title: '亦心Ai'
        }
      },
      {
        path: 'workshopHome',
        name: 'workshopHome',
        component: () => import('@/views/aiWorkshop/workshopHome.vue'),
        meta: {
          page: 'WORK',
          title: '亦心Ai'
        }
      },
      {
        path: 'personal',
        name: 'personal',
        component: () => import('../views/aiGallery/personalView.vue'),
        meta: {
          title: '亦心Ai'
        }
      },
      {
        path: 'task',
        name: 'task',
        component: () => import('../views/aiGallery/taskCenter.vue'),
        meta: {
          title: '亦心Ai'
        }
      },
      {
        path: 'message',
        name: 'message',
        component: () => import('../views/aiGallery/messageCenter.vue'),
        meta: {
          title: '亦心Ai'
        }
      },
      {
        path: '/making',
        name: 'making',
        component: () => import('@/views/aiGenerate/aiGenerate.vue'),
        meta: {
          page: 'MAKING',
          title: '亦心Ai'
        },
        alias: '/makingForBHU'
      },
      {
        path: '/makingForModel',
        name: 'makingForModel',
        component: () => import('@/views/aiGenerateForModel/aiGenerateForModel.vue'),
        meta: {
          page: 'MODEL',
          title: '亦心Ai'
        }
      },
      {
        path: '/fluxMakingForModel',
        name: 'fluxMakingForModel',
        component: () => import('@/views/fluxGenerateForModel/fluxGenerateForModel.vue'),
        meta: {
          page: 'MODEL',
          title: '亦心Ai'
        }
      }
    ]
  },
  {
    path: '/aiEdit',
    name: 'aiEdit',
    component: () => import('../views/aiEdit/aiEdit.vue'),
    meta: {
      page: 'MAKING',
      title: '亦心Ai'
    }
  },
  {
    path: '/workMakingImg',
    name: 'workMakingImg',
    component: () => import('@/views/aiWorkshop/workMakingImg.vue'),
    meta: {
      page: 'MAKING',
      title: '亦心Ai'
    }
  },
  {
    path: '/creating',
    name: 'creating',
    component: () => import('@/views/creatSetting/aiSetting.vue'),
    meta: {
      title: '亦心Ai'
    }
  },
  {
    path: '/sassCreating',
    name: 'sassCreating',
    component: () => import('@/views/creatSetting/sassCreating.vue'),
    meta: {
      title: '亦心Ai'
    }
  },
  {
    path: '/aiCartoon',
    name: 'aiCartoon',
    component: () => import('@/views/aiCartoon/aiCartoon.vue'),
    meta: {
      title: '亦心Ai'
    }
  },
  // 联想小程序(亦心AI画)
  {
    path: '/miniAIGC',
    name: 'miniAIGC',
    // component: () => import('@/views/lenovoMini/miniAI.vue'),
    redirect: '/home?from=lenovo',
    meta: {
      title: '亦心AI画'
    }
  },
  // 联想小程序(亦心AI视频)
  {
    path: '/miniAIVideo',
    name: 'miniAIVideo',
    // component: () => import('@/views/lenovoMini/miniAI.vue'),
    redirect: '/aiCartoon?from=lenovo',
    meta: {
      title: '亦心AI视频'
    }
  }
]

// 手机端路由
const routeH5 = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/H5/overallLayout.vue'),
    redirect: '/home/galleryList',
    children: [
      {
        path: 'galleryList',
        name: 'galleryList',
        component: () => import('../views/H5/home/homeExplore.vue'),
        meta: {
          page: 'HOME',
          title: '亦心Ai'
        }
      },
      {
        path: 'artGalleryList',
        name: 'artGalleryList',
        component: () => import('../views/H5/aiGallery/artGalleryList.vue'),
        meta: {
          page: 'COMMUNITY',
          title: '亦心Ai'
        }
      },
      {
        path: 'task',
        name: 'task',
        component: () => import('../views/aiGalleryPhone/taskCenter.vue'),
        meta: {
          title: '亦心Ai'
        }
      },
      {
        path: 'workshopHome',
        name: 'workshopHome',
        component: () => import('@/views/H5/aiWork/workMethod.vue'),
        meta: {
          page: 'WORK',
          title: '亦心Ai'
        }
      },
      {
        path: 'personal',
        name: 'personal',
        component: () => import('@/views/H5/mySetting/myView.vue'),
        meta: {
          title: '亦心Ai'
        }
      }
    ]
  },
  {
    path: '/makingForModel',
    name: 'makingForModel',
    component: () => import('@/views/H5/aiModel/aiModel.vue'),
    meta: {
      page: 'MODEL',
      title: '亦心Ai'
    }
  },
  {
    path: '/home/prompt',
    name: 'prompt',
    component: () => import('@/views/aiGalleryPhone/promptWords.vue'),
    meta: {
      title: '亦心Ai'
    }
  },
  {
    path: '/making',
    name: 'making',
    component: () => import('../views/H5/aiGenerate/aiGenerateForPhone.vue'),
    meta: {
      page: 'MAKING',
      title: '亦心Ai'
    }
  },
  {
    path: '/fluxMakingForModel',
    name: 'fluxMakingForModel',
    component: () => import('../views/H5/aiGenerate/aiGenerateForPhone.vue'),
    meta: {
      page: 'MAKING',
      title: '亦心Ai'
    }
  },
  {
    path: '/resultOfMaking',
    name: 'resultOfMaking',
    component: () => import('../views/H5/aiGenerate/aiGenerateResult.vue'),
    meta: {
      title: '亦心Ai'
    }
  },
  {
    path: '/indexforJXT',
    name: 'indexforJXT',
    component: () => import('../views/aiGalleryPhone/galleryList.vue'),
    meta: {
      title: '亦心Ai'
    }
  },
  {
    path: '/workMakingImg',
    name: 'workMakingImg',
    component: () => import('../views/H5/aiWork/workMakingImg'),
    meta: {
      title: '亦心Ai'
    }
  },
  {
    path: '/workSearch',
    name: 'workSearch',
    component: () => import('../views/H5/aiWork/workSearch.vue'),
    meta: {
      title: '亦心Ai'
    }
  },
  {
    path: '/followFans',
    name: 'followFans',
    component: () => import('@/views/H5/followAndFans/relationPerson.vue'),
    meta: {
      title: '亦心Ai'
    }
  },
  {
    path: '/home/message',
    name: 'message',
    component: () => import('@/views/H5/aiMessage/aiMessage.vue'),
    meta: {
      title: '亦心Ai'
    }
  },
  {
    path: '/editOfExtend',
    name: 'editOfExtend',
    component: () => import('../views/H5/home/editOfExtend.vue'),
    meta: {
      page: 'MAKING',
      title: '亦心Ai'
    }
  },
  {
    path: '/editOfShade',
    name: 'editOfShade',
    component: () => import('../views/H5/home/editOfShade.vue'),
    meta: {
      page: 'MAKING',
      title: '亦心Ai'
    }
  },
  {
    path: '/home/task',
    name: 'task',
    component: () => import('@/views/H5/mySetting/taskCenter.vue'),
    meta: {
      title: '亦心Ai'
    }
  },
  {
    path: '/aiCartoon',
    name: 'aiCartoon',
    component: () => import('@/views/H5/aiCartoon/aiCartoon.vue'),
    meta: {
      title: 'AI视频'
    }
  },
  {
    path: '/makeCartoon',
    name: 'makeCartoon',
    component: () => import('@/views/H5/aiCartoon/making/makeCartoon.vue'),
    meta: {
      title: 'AI视频'
    }
  },
  {
    path: '/flashDrawing',
    name: 'flashDrawing',
    component: () => import('../views/H5/flashDrawing/flashDrawing.vue'),
    meta: {
      title: '闪绘',
      page: 'FLASHDRAWING'
    }
  },
  {
    path: '/historyForFlash',
    name: 'historyForFlash',
    component: () => import('../views/H5/flashDrawing/historyForFlash.vue'),
    meta: {
      title: '闪绘',
      page: 'FLASHDRAWING'
    }
  },
  // 小程序闪绘页面
  {
    path: '/miniFlashDrawing',
    name: 'miniFlashDrawing',
    component: () => import('../views/H5/miniFlashDrawing/flashDrawing.vue'),
    meta: {
      title: '闪绘',
      page: 'FLASHDRAWING'
    }
  }
]

// 创建
const router = createRouter({
  history: createWebHashHistory(),
  routes: (isPhone()) ? routeH5 : routePC
})

// 路由守卫
router.beforeEach((to, from, next) => {
  if (isPhone()) {
    window.resetTemplateWidth(750) // 初始化H5的rem模板宽
  } else {
    window.resetTemplateWidth(1920) // 初始化PcWeb的rem模板宽
  }
  ElMessage.closeAll()
  if (isPhone()) {
    window.updateStyle(0)
  }
  // 标题补充
  document.title = to.meta.title || 'Himirage.AI'
  router.from = from.path
  router.to = to.path
  next()
})

export default router
