
import { isFromLenovo } from './ifAigcVersion.js'

/** 修改前 */
// import Cookies from 'js-cookie'
// export const handleLinkRedirection = (linkUrl) => {
//   if (Cookies.get('from') && Cookies.get('from') === 'lenovo') {
//     window.location.href = linkUrl
//   } else {
//     window.open(linkUrl, '_blank')
//   }
// }

/** ** 修改后 ***/
// 链接跳转处理函数
export const handleLinkRedirection = (linkUrl) => {
  // 检查linkUrl是否为有效的URL
  if (!linkUrl || typeof linkUrl !== 'string' || linkUrl.trim() === '') {
    console.warn('Invalid link URL provided.')
    return
  }
  // 根据Cookie值决定跳转方式
  if (isFromLenovo()) {
    window.location.href = linkUrl // 当前窗口跳转
  } else {
    window.open(linkUrl, '_blank') // 新窗口打开
  }
}

export function getBrowser () {
  const curBrowser = {
    isCefBrowser: false,
    isAndroidBrowser: false
  }
  const userAgent = navigator.userAgent || window.opera
  const isCefBrowser = (/(OHOS)/i.test(userAgent)) // Chromium Embedded Framework 是一个浏览器简称CEF
  curBrowser.isCefBrowser = isCefBrowser
  curBrowser.isAndroidBrowser = /(Android)/i.test(userAgent)
  return curBrowser
}
