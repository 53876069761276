<template>
  <el-config-provider :locale="locale">
    <div id="app">
      <!--系统头-->
      <headerPc
        v-if="!isPhone() && getBrowerType() !== 'pc' && !store.state.isFromBHU && ['saas'].indexOf(store.state.iframeFrom) === -1"
        @loginClick="goToLogin"
        :assetsObj="state.assetsObj + ''" />
      <!-- 路由页面 -->
      <router-view @loginClick="state.showLogin = true" />

      <!--登录弹窗-->
      <el-dialog style="z-index: 9999" v-model="state.showLogin" class="dialogR unInvertColor">
        <iframe :src="state.loginUrl" class="iframeClass " :frameborder="0" sandbox="allow-scripts allow-top-navigation allow-same-origin allow-popups" />
      </el-dialog>

      <!--购买SVIP/次数卡(手机)-->
      <el-drawer class="vip-drawer" :show-close="false" :with-header="false" direction="btt" size="80%"
                 v-model="state.buyVipDialog_h5" @close="refreshAiTimes">
        <iframe v-if="state.buyVipDialog_h5" style="width: 100%; height: 100%; border: none;" :src="state.svip_url_h5" />
      </el-drawer>

      <!--次数不足弹窗(手机)-->
      <el-dialog class="hintDialog" v-model="state.noNumDialog_h5" width="80vw">
        <div class="noNumBox">
          <span style="font-size: 1rem; font-weight: bold">
            您的AI点数不足
          </span>
          <span style="font-size: 1rem; font-weight: bold">
            请开通SVIP会员或直接购买更多点数
          </span>
          <el-button style="width: 30%; margin: 3rem auto 0" type="primary" @click="goToBuyAiTimes()">
            详情
          </el-button>
        </div>
      </el-dialog>

      <!--购买次数(PC)-->
      <el-dialog :class="(isFromLenovo()) ? 'buyDialogForLenovo' : 'buyDialog'" class="unInvertColor" v-model="state.buyVipDialog_pc" @close="refreshAiTimes">
        <iframe v-if="state.buyVipDialog_pc" class="buyIframe" :src="state.svip_url_pc"/>
      </el-dialog>

      <!--次数不足弹窗(PC)-->
      <el-dialog class="hintDialog" v-model="state.noNumDialog_pc" width="400px">
        <div class="noNumBox">
          <span style="font-size: 1rem; font-weight: bold">
            您的AI点数已用尽
          </span>
          <span style="font-size: 1rem; font-weight: bold">
            请开通会员获取更多AI点数
          </span>
          <el-button style="width: 30%; margin: 3rem auto 0" type="primary" @click="goToBuyAiTimes()">
            详情
          </el-button>
        </div>
      </el-dialog>

      <!--服务协议弹窗(通用)-->
      <serveDialog ref="serve"></serveDialog>
    </div>
  </el-config-provider>
</template>

<script setup>
import events from '@/bus/bus.js'
import Cookies from 'js-cookie'
import * as Base64 from 'js-base64'
import message from '@/utils/system/message.js'
import en from 'element-plus/dist/locale/en.mjs'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import headerPc from './components/system/headerPc.vue'
import serveDialog from '@/components/system/serveDialog.vue'
import userInfoService from '@/utils/service/userinfoservice'
import { queryall, queryparambyname, getUserAiTimes, saveVisitLog, getuserinfoForBHU, getFontList, unreadCount, queryOrganizeStyle } from '@/utils/api/api'
import { computed, reactive, ref, inject, onBeforeMount, onUnmounted, onMounted, watch } from 'vue'
import { isPhone, getQueryFromLocation, getBrowerType } from '@/utils/system/systemUtils'
import { getLenovoInfo, loginByLenovo } from '@/utils/api/miniApi'
import { QWebChannel } from '@/../public/static/qwebchannel.js'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { getBrowser } from '@/utils/linkUtils'
import { isWxminiAigc, isFromLenovo } from '@/utils/ifAigcVersion'
// import { handleLinkRedirection, getBrowser } from '@/utils/linkUtils.js'
const router = useRouter()
const route = useRoute()
const language = ref(window.SITE_CONFIG.defaultLanguage)
const locale = computed(() => (language.value === 'CN' ? zhCn : en))
const store = useStore()
const userInfoGlobal = ref(inject('$userInfoGlobal'))
const state = reactive({
  userInfoGlobal,
  lenovoMiniNo: 0, // 联想小程序编号
  assetsObj: '', // ai点数资产
  showLogin: false, // 登录弹窗
  svip_url_pc: '', // 会员购买地址(pc)
  svip_url_h5: '', // 会员购买地址(手机)
  buyVipDialog_pc: false, // 会员购买弹窗(pc)
  buyVipDialog_h5: false, // 会员购买弹窗(手机)
  noNumDialog_pc: false, // 次数不足弹窗(pc)
  noNumDialog_h5: false, // 次数不足弹窗(手机)
  loginUrl: window.SITE_CONFIG.websiteBaseUrl + '/#/UserLogin?page=' + Base64.encode(window.location.href) + '&source=18' // 登录地址
})

// 登录状态
const isLogin = computed(() => {
  return state.userInfoGlobal.isLogin
})
watch(isLogin, (val) => {
  // 处理组织用户登录/退出逻辑
  if (state.userInfoGlobal.organizeid > 1) {
    if (val) getOrganizeModel('lora') // 组织风格列表
    if (val) getOrganizeModel('loraflux') // 组织风格列表
    if (!val) getBaseModel('lora') // 更新风格列表
    if (!val) getBaseModel('loraflux') // 更新风格列表
  }
})

// 初始化
onBeforeMount(() => {
  window.isFromLenovo = isFromLenovo // 创建全局联想小程序的判断方法
  window.isWxminiAigc = isWxminiAigc // 创建全局微信小程序的判断方法
  window.toLogin = goToLogin // 全局登录方法
  window.buyAiTimes = goToBuyAiTimes // ai次数购买
  window.showServeDialog = openServeDialog // 服务协议弹窗
  window.addEventListener('message', handleEvent) // 登录回调
  isPhone() // 机器识别
})
onMounted(() => {
  doLenovoMiniAppOperation()
  saveLog() // 记录日志
  txAdvertisement()
  isFromBHU() // 判断访问是否是北航
  loginInit()
})

// 销毁
onUnmounted(() => {
  window.removeEventListener('message', handleEvent)
  events.off('refreshAiTimes')
  events.off('refreshMsgNum')
  events.off('login')
})

/**
 * 初始化登录相关逻辑
 */
const loginInit = async () => {
  const query = getQueryFromLocation()
  if (query.login === 'miniaigc') {
    state.showLogin = true
  }
  if (Object.keys(query).length) {
    store.commit('setConfigData', { locationQuery: query })
  }
  if (getBrowerType() === 'pc' || getBrowerType() === 'pcWeb') {
    // qt登录
    const token = await getPcToken()
    await userInfoService.setToken(token)
    inituserInfoGlobal(false)
    return
  }
  if (query.from) {
    Cookies.set('from', query.from)
  }
  if (query.token) {
    const token = query.token
    await userInfoService.setToken(token)
    inituserInfoGlobal(false)
    const newQuery = { ...route.query }
    delete newQuery.token
    // 如果有其他查询参数，则替换当前路由的查询参数
    if (Object.keys(newQuery).length > 0) {
      await router.replace({ ...route, query: newQuery })
    } else {
    // 如果只有token，则清空当前路由的查询参数
      await router.replace({ path: route.path, query: {} })
    }
    return
  }
  if (query.dnum && query.dnum !== 'undefined') {
    router.push({ path: '/home/personal' })
  }
  // 闪绘小程序判断分享跳转到闪绘
  if (query.type && query.type !== 'undefined' && (query.type === 'share' || query.type === 'sharePyq')) {
    router.push({ path: '/miniFlashDrawing' })
  }
  inituserInfoGlobal()
}

/**
 * 登录回调
 * @param e 事件对象
 */
const handleEvent = async (e) => {
  const msg = e.data
  // 北航登录
  if (e.data.type === 'BHU') {
    const authenticationkey = e.data?.authenticationkey
    if (authenticationkey) {
      try {
        const { code, data } = await getuserinfoForBHU({ authenticationkey })
        if (code === 0) {
          const { outuserid, token } = data
          Cookies.set('outuserid', outuserid, { sameSite: 'none', secure: true })
          await userInfoService.setToken(token)
          events.emit('getImgList')
          await refreshAiTimes()
          await refreshMsgNum()
        }
      } catch (e) {
        console.log('获取token失败', e)
      }
    }
    return
  }
  // 通用登录
  if (msg && Object.prototype.toString.call(msg) === '[object String]') {
    if (msg.includes('wx.tenpay.com')) {
      window.location.href = msg + '&redirect_url=' + encodeURIComponent(window.location.href)
    }
    // 判断是否是字符串
    if (msg === 'login') {
      inituserInfoGlobal()
      state.showLogin = false
    }
  }
}

/**
 * 初始化用户信息
 */
const inituserInfoGlobal = async (isNeedGetUserInfo = true) => {
  if (isNeedGetUserInfo) state.userInfoGlobal = await userInfoService.getUserData(state.userInfoGlobal)
  if (state.userInfoGlobal.isLogin || !isNeedGetUserInfo) {
    state.userInfoGlobal.isLogin = false
    state.userInfoGlobal.isLogin = true
    refreshAiTimes()
    refreshMsgNum()
  }
}

/**
 * 打开服务协议
 */
const serve = ref()
const openServeDialog = () => {
  serve.value.showAIserviceagreement()
}

/**
 * 更新ai资产数
 */
const refreshAiTimes = () => {
  return new Promise((resolve, reject) => {
    // let that = this;
    if (state.userInfoGlobal.isLogin === false) return
    getUserAiTimes().then(res => {
      if (res.code === 0) {
        const list = res.data
        if (list && list.length > 0) {
          for (let i = 0; i < list.length; i++) {
            const one = list[i]
            if (one.assetstype === 'AITIMES') {
              state.assetsObj = one.remaintimes
              store.commit('setUserInfo', { remaintimes: one.remaintimes })
            }
          }
        }
      }
    }).catch(function () {})
  })
}

/**
 * ai点数购买
 * @param type 套餐类型
 */
const goToBuyAiTimes = (type) => {
  // 初始化地址
  const tokenH5 = '?token=' + userInfoService.getToken() + '&bodyType=' + 15 + '&TabId=2' + '&source=18'
  let source = ''
  if (state.lenovoMiniNo === 1) source = '70'
  if (state.lenovoMiniNo === 2) source = '71'
  const tokenPc = '?token=' + userInfoService.getToken() + ((type && type === 'svip') ? '' : ('&TabId=2')) + (window.isFromLenovo() ? ('&source=' + source) : '')
  state.svip_url_h5 = window.SITE_CONFIG.svip_url_h5 + tokenH5
  state.svip_url_pc = window.SITE_CONFIG.svip_url_pc + tokenPc
  // 浏览器校验
  if (isPhone()) {
    state.buyVipDialog_h5 = true
  } else {
    state.buyVipDialog_pc = true
  }
}

/**
 * 去登录
 */
const goToLogin = async () => {
  const from = Cookies.get('from')
  if (from && from === 'lenovo') { // 联想小程序
    lenovoLogin()
  } else if (getBrowerType() === 'pc' || getBrowerType() === 'pcWeb') {
    // qt登录
    const token = await getPcToken()
    userInfoService.setToken(token).then(() => {
      inituserInfoGlobal(false)
    }, (e) => {
      message.warning('登录异常,请重试')
    })
  } else if (isWxminiAigc() || route.query.login === 'miniaigc') { // 微信小程序登录，如果传来login=aigc参数，就是微信小程序其他登录
    // eslint-disable-next-line no-undef
    wx.miniProgram.navigateTo({
      url: '/pages/index/wxLogin'
    })
  } else {
    state.showLogin = true
  }
}

/**
 * 联想小程序登录
 */
const lenovoLogin = () => {
  if (!Cookies.get('lenovoIsLogin')) {
    console.log('联想登录弹出框')
    // 打开登录弹出框
    // 即调起宿主APP Lenovo ID SDK的登录弹出框。当用户登录成功后，可通过接口  jsbridge.onLoginStatusChange() 监听到登录状态，并获取到用户信息
    window.jsbridge.showLogin().then(res => {
      console.log('打开登录弹出框成功', res)
    }).catch(err => {
      console.log('打开登录弹出框失败', err)
    })
  }
}

/**
 * 获取通用模型
 * @param modelgroup 模型参数
 */
const getBaseModel = (modelgroup) => {
  queryall({ modelgroup }).then(function (res) {
    if (res.code === 0) {
      const dataList = res.data.list
      if (modelgroup === 'main') store.commit('setConfigData', { baseModelList: dataList })
      if (modelgroup === 'lora') store.commit('setConfigData', { loraModelList: dataList })
      if (modelgroup === 'model') store.commit('setConfigData', { modelModelList: dataList })
      if (modelgroup === 'lorafont') store.commit('setConfigData', { loraFontModelList: dataList })
      if (modelgroup === 'turbo') store.commit('setConfigData', { turboModelList: dataList })
      if (modelgroup === 'flashpaint') store.commit('setConfigData', { flashPaintModelList: dataList })
      if (modelgroup === 'flux') store.commit('setConfigData', { fluxModelList: dataList })
      if (modelgroup === 'loraflux') store.commit('setConfigData', { lorafluxModelList: dataList })
      if (modelgroup === 'gcmotion') {
        const styleList = []
        dataList.forEach(function (item) {
          item.list.forEach(function (one) {
            styleList.push(one)
          })
        })
        store.commit('setConfigData', { gcmotionList: styleList })
      }
      if (modelgroup === 'gcmotionimg') {
        const styleList = []
        dataList.forEach(function (item) {
          item.list.forEach(function (one) {
            styleList.push(one)
          })
        })
        store.commit('setConfigData', { gcmotionimgList: styleList })
      }
      if (modelgroup === 'mp4imgexample') {
        const styleList = []
        dataList.forEach(function (item) {
          item.list.forEach(function (one) {
            styleList.push(one)
          })
        })
        store.commit('setConfigData', { mp4imgexampleList: styleList })
      }
      if (modelgroup === 'loragoods') store.commit('setConfigData', { loraGoodsModelList: dataList })
    }
  })
}

/**
 * 获取组织模型
 * @param modelgroup 模型参数
 */
const getOrganizeModel = (modelgroup) => {
  queryOrganizeStyle({ modelgroup }).then(function (res) {
    if (res.code === 0) {
      if (!res.data.list) return
      // 更新风格模型
      if (modelgroup === 'lora') {
        const dataList = [...store.state.loraModelList, ...res.data.list]
        store.commit('setConfigData', { loraModelList: dataList })
      }
      if (modelgroup === 'loraflux') {
        const dataList = [...store.state.lorafluxModelList, ...res.data.list]
        store.commit('setConfigData', { lorafluxModelList: dataList })
      }
      // 保存私有模型ename列表(用于逻辑判断)
      const loraPrivateModelEnameList = []
      res.data.list.forEach((item) => {
        item.list.forEach((one) => {
          loraPrivateModelEnameList.push(one.ename)
        })
      })
      store.commit('setConfigData', { loraPrivateModelEnameList: loraPrivateModelEnameList })
    }
  })
}

/**
 * 获取采样器/精修模型
 */
const getSampler = async (name) => {
  queryparambyname({ systype: 0, name }).then(function (res) {
    if (res.code === 0) {
      const data = res.data
      if (name === 'ai_model') store.commit('setConfigData', { samplerList: data })
      if (name === 'ai_adetailer') {
        // select无法识别value为空，所以value设置为empty，请求接口和回显数据需要处理
        store.commit('setConfigData', {
          adetailerList: [...data, {
            instruct: '无模型',
            memo: '无模型',
            name: '',
            systype: '0',
            value: 'empty',
            valuetype: 'string'
          }]
        })
      }
    }
  })
}

/**
 * 北航标识
 */
const isFromBHU = () => {
  store.commit('setIsFromBHU', window.location.href.includes('/makingForBHU'))
}

/**
 * 初始化字体列表
 */
const loadFont = async () => {
  try {
    const { code, data } = await getFontList()
    if (code === 0) {
      const fontList = data
      const style = document.createElement('style')
      fontList.forEach(font => {
        style.innerHTML += `@font-face {font-family: ${font.name};src: url(${font.url}) format('truetype');}`
      })
      document.head.appendChild(style)
      store.commit('setConfigData', { fontList: fontList })
    }
  } catch (e) {}
}

/**
 * 获取PC端token
 */
const getPcToken = () => {
  return new Promise((resolve) => {
    try { // qt方法调用
      const browser = getBrowser()
      if (browser.isCefBrowser) {
        window.PhotoSirExtension.getUserToken('', (err, returnData) => {
          console.log('cef-->pcAutoLoginDialog-->return:' + err + returnData)
          resolve(returnData)
        })
        return
      }
      let bridge
      const webChannel = new QWebChannel(window.qt.webChannelTransport,
        function (channel) {
          bridge = channel.objects.bridge
          bridge.getUserToken().then(function (token) {
            resolve(token)
          })
          return webChannel
        })
    } catch (e) { resolve() }
  })
}

/**
 * 退出登录的监听
 */
events.on('logout', () => {
  if (getBrowerType() === 'pc' || getBrowerType() === 'pcWeb') {
    return
  }
  if (window.isFromLenovo()) {
    window.jsbridge.logout()
    return
  }
  userInfoService.logout(state.userInfoGlobal).then(() => {
    inituserInfoGlobal()
    if (router.currentRoute.value.name === 'personal' || router.currentRoute.value.name === 'task') {
      router.push('/home/galleryList')
    }
  })
})

/**
 * 处理联想小程序相关的操作
 */
const doLenovoMiniAppOperation = () => {
  setTimeout(() => {
    console.log('======app======>', route.query.from)
    if (route.query.from && route.query.from === 'lenovo') {
      Cookies.set('from', 'lenovo', { domain: window.SITE_CONFIG.domain })
    }
    if (window.isFromLenovo()) {
      /**
       * 异步获取小程序启动信息
       * 小程序启动后，获取启动小程序的参数和信息
       */
      window.jsbridge.getLaunchOptions().then(res => {
        console.log('联想小程序获取启动信息', res)
        if (res && res.query && res.query.appid && res.query.appid === '202411261501439953') state.lenovoMiniNo = 1 // 亦心AI画
        if (res && res.query && res.query.appid && res.query.appid === '202502131803475807') state.lenovoMiniNo = 2 // 亦心AI视频
        store.commit('setUserInfo', { lenovoMiniNo: state.lenovoMiniNo })
        getLenovoUserInfo()
        requestGrant()
      })
      /**
       * 小程序接收通知
       * 小程序的框架会负责将jsbridge协议的数据解析为json格式的字符串，并通知到小程序页面
       */
      window.jsbridge.onSmallAppNotify(res => {
        console.log('联想小程序接收通知', res)
      })
      /**
       * 异步获取系统信息
       * SDKVersion   string 客户端基础库版本（即JSSDK版本）
       * model        string 设备型号
       * brand        string 设备品牌
       * system       string 操作系统及版本
       * platform     string 客户端平台(windows、android)
       * pixelRatio   number 设备像素比
       * screenWidth  number 屏幕宽度，单位px
       * screenHeight number 屏幕高度，单位px
       */
      window.jsbridge.getSystemInfo().then(res => {
        console.log('联想小程序获取系统信息', res)
      })
      /**
       * 获取登录状态
       * 监听登录状态发生变化
       */
      window.jsbridge.onLoginStatusChange(res => {
        if (res.statusCode === 1) {
          console.log('联想登录成功', res)
          getLenovoUserInfo()
        } else if (res.statusCode === 0) {
          console.log('联想退出登录', res)
          userInfoService.logout(state.userInfoGlobal).then(() => {
            inituserInfoGlobal()
            Cookies.set('from', 'lenovo', { domain: window.SITE_CONFIG.domain })
            Cookies.set('lenovoIsLogin', '', { domain: window.SITE_CONFIG.domain })
            Cookies.set('lenovoUserInfo', '', { domain: window.SITE_CONFIG.domain })
            if (state.lenovoMiniNo === 1) router.push('/miniAIGC')
            if (state.lenovoMiniNo === 2) router.push('/aiCartoon')
          })
        }
      })
      /**
       * 显示账号中心
       * 小程序启动后，默认在小程序框体上不显示账号中心（可管理登录/登出联想账号），若有显示的需求，可根据调用此接口控制
       * @param show int 0：表示隐藏 1：表示显示
       */
      window.jsbridge.showAccountCenter(0)
    }
  }, 500)
}

/**
 * 获取用户信息
 */
const getLenovoUserInfo = () => {
  window.jsbridge.getUserInfo().then(response => {
    console.log('联想获取用户信息', response)
    if (response.statusCode === 1) {
      state.lenovoUserInfo = response.userInfo
      state.lenovoUserInfo.token = response.token
      let source = '70'
      if (state.lenovoMiniNo === 1) source = '70'
      if (state.lenovoMiniNo === 2) source = '71'
      loginByLenovo({ account: response.token, source: source }).then((res0) => {
        console.log('亦心登录', res0)
        if (res0 && res0.code === 0) {
          userInfoService.setToken(res0.data.token).then(res => {
            inituserInfoGlobal(false)
            Cookies.set('from', 'lenovo', { domain: window.SITE_CONFIG.domain })
            Cookies.set('lenovoIsLogin', 'true', { domain: window.SITE_CONFIG.domain })
            Cookies.set('lenovoUserInfo', JSON.stringify(state.lenovoUserInfo), { domain: window.SITE_CONFIG.domain })
            if (state.lenovoMiniNo === 1) router.push('/miniAIGC')
            if (state.lenovoMiniNo === 2) router.push('/aiCartoon')
          })
        }
      })
    } else {
      Cookies.set('from', 'lenovo', { domain: window.SITE_CONFIG.domain })
      Cookies.set('lenovoIsLogin', '', { domain: window.SITE_CONFIG.domain })
      Cookies.set('lenovoUserInfo', '', { domain: window.SITE_CONFIG.domain })
      if (state.lenovoMiniNo === 1) router.push('/miniAIGC')
      if (state.lenovoMiniNo === 2) router.push('/aiCartoon')
    }
  })
}

/**
 * 请求授权使用联运能力
 */
const requestGrant = () => {
  let source = '70'
  if (state.lenovoMiniNo === 1) source = '70'
  if (state.lenovoMiniNo === 2) source = '71'
  getLenovoInfo({ source: source }).then((res) => {
    console.log('亦心获取联想小程序信息', res)
    if (res && res.code === 0) {
      window.jsbridge.requestGrant(res.data.appId, res.data.privateKey).then(() => { // 授权成功
        console.log('联想小程序联运能力授权成功')
      }).catch(() => { // 授权失败
        console.log('联想小程序联运能力授权失败')
      })
    }
  })
}

/**
 * 记录访问日志
 * 首页(HOME)  生成页(MAKING)  社区主页(COMMUNITY)  配方主页(WORK)  模特主页(MODEL)
 */
const saveLog = () => {
  setTimeout(() => {
    let ios = isPhone() ? 'H5' : ''
    const query = getQueryFromLocation()
    if (query.from === 'wx') ios = 'WX'
    if (window.isFromLenovo()) ios = 'LEN'
    const page = route?.meta?.page
    if (!page) return
    const param = {
      thcode: ios + page,
      source: 18
    }
    console.log('记录访问日志', param)
    saveVisitLog(param)
  }, 500)
}

/**
 * 腾讯广告相关
 */
const txAdvertisement = async () => {
  const url = window.location.href
  const params = new URLSearchParams(url.split('?')[1])
  try {
    let impressionid = ''
    if (params.get('gdt_vid')) {
      impressionid = params.get('gdt_vid')
      console.log('gdt_vid')
    } else {
      if (params.get('qz_gdt')) {
        impressionid = params.get('qz_gdt')
        console.log('qz_gdt')
      } else {
        impressionid = params.get('wx_traceid').split('#/')[0]
        console.log('wx traceid')
      }
    }
    Cookies.set('impressionid', impressionid, { domain: window.SITE_CONFIG.domain, sameSite: 'none', secure: true })
  } catch (e) {}
  // 如果存在click_id，则表示点击了广告
  // if (params.click_id) {
  //   Cookies.set('click_id', result.click_id)
  //   // await saveAds(result)s
  //   /**
  //    * 此处上传到服务器，用于统计广告点击量
  //    */
  // }
}

/**
 * 更新信息条数
 */
const refreshMsgNum = async () => {
  const sysUnread = await getMessageNum(1)
  const msgUnread = await getMessageNum(2)
  state.unread = sysUnread + msgUnread
  store.commit('setUserInfo', { unread: state.unread, sysUnread: sysUnread, msgUnread: msgUnread })
}
events.on('refreshMsgNum', refreshMsgNum)

/**
 * 获取未读消息数量
 */
const getMessageNum = (type) => {
  return new Promise(resolve => {
    const params = {
      type: type,
      source: 8
    }
    unreadCount(params).then((res) => {
      if (res.code === 0) {
        resolve(res.data.count)
      } else {
        resolve(0)
      }
    })
  })
}

/**
 * 初始化配置数据
 */
events.on('refreshAiTimes', refreshAiTimes)
events.on('login', () => { state.showLogin = true })
loadFont() // 字体列表
getBaseModel('main') // 基础模型列表
getBaseModel('lora') // 风格模型列表
getBaseModel('model') // 风格模型列表
getBaseModel('gcmotion') // AI视频风格(文生视频)模型列表
getBaseModel('gcmotionimg') // AI视频风格(图生视频)模型列表
getBaseModel('mp4imgexample') // AI视频风格(图生视频)示例
getBaseModel('loragoods') // 风格模型列表
getBaseModel('lorafont') // 艺术字模型列表
getBaseModel('turbo') // 闪绘模型
getBaseModel('flashpaint') // 闪绘模型
getBaseModel('flux') // 文/图生图基础模型
getBaseModel('loraflux') // 文/图生图风格模型列表
getSampler('ai_model') // 采样器列表
getSampler('ai_adetailer') // 精修列表
</script>

<style lang="scss" scoped>
  #app {
    /*background: #f3f4f7;*/
    font-family: "Microsoft YaHei", serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }

  ::v-deep(.dialogR, .el-dialog) {
    width: max-content;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    padding: 0 !important;
    background-color: #ffffff00;

    .el-dialog__header {
      padding: 0 !important;
      margin: 0 !important;
    }

    .el-dialog__body {
      padding: 0;
      border-radius: 10px;
    }

  }

  @media (min-width: 740px) {
    .iframeClass {
      width: 700PX !important;
      height: 560PX !important;
      border-radius: 10px;
    }
  }

  @media (max-width: 740px) {
    .iframeClass {
      width: 370PX !important;
      height: 500PX !important;
      border-radius: 10px;
    }
  }

  ::v-deep(.vip-drawer) {
    border-radius: 1.3rem 1.3rem 0 0;

    .el-drawer__body {
      display: flex;
      flex: 1;
      flex-direction: column;
    }
  }

  ::v-deep(.buyDialog) {
    width: 60rem;
    height: 25rem;
    border-radius: 1.1rem;
    padding: 0 !important;
    background-color: #ffffff00;

    .buyIframe {
      min-width: 500px;
      width: 60rem;
      height: 35rem;
      border: none;
      border-radius: 1rem;
    }

    .el-dialog__header {
      padding: 0;
    }

    .el-dialog__body {
      padding: 0;
    }
  }

  ::v-deep(.buyDialogForLenovo) {
    width: 80rem;
    height: 55rem;
    margin-top: 5%;
    border-radius: 1.1rem;
    padding: 0 !important;
    background-color: #ffffff00;

    .buyIframe {
      min-width: 500px;
      width: 80rem;
      height: 55rem;
      border: none;
      border-radius: 1rem;
    }

    .el-dialog__header {
      padding: 0;
    }

    .el-dialog__body {
      padding: 0;
    }
  }

  ::v-deep(.hintDialog) {
    border-radius: 1rem;

    .noNumBox {
      width: 100%;
      display: flex;
      flex-flow: column;
      justify-content: center;

      span {
        width: 100%;
        text-align: center;
      }
    }
  }
</style>
