/**
 * axios封装：请求拦截，响应拦截，错误编码
 */
import axios from 'axios'
import { getToken } from '../service/userinfoservice'
import events from '@/bus/bus'
import message from '../system/message'

/**
 * 默认10S请求超时
 */
axios.defaults.timeout = 200000
/**
 * 设置post请求头
 */
axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded;charset=UTF-8'
/**
 * 请求拦截器
 */

/**
 * 百度服务器校验
 */
const baiduServerApiList = [
  '/baidu/api/v3/ai/handshake',
  '/baidu/api/v3/ai/stylelist',
  '/baidu/api/v3/ai/refine',
  '/baidu/api/v3/ai/imggetimg'
]
axios.interceptors.request.use(
  (config) => {
    if (window.SITE_CONFIG.is_BD_server) {
      if (baiduServerApiList.includes(config.url)) {
        config.url = window.SITE_CONFIG.apiBaseUrl + config.url
        config.headers.token = getToken()
        return config
      }
    } else {
      // 接口url重新拼接
      config.url = window.SITE_CONFIG.apiBaseUrl + config.url
      // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
      // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
      config.headers.token = getToken()
      return config
    }
  },
  (error) => {
    return Promise.error(error)
  }
)
// 响应拦截器
axios.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      if (response.data.code === 12) {
        events.emit('logout', '')
        message.warning(response.data.result)
      } else if (response.data.code !== 0) {
        message.warning(response.data.result)
      }
      return Promise.resolve(response)
    } else {
      return Promise.reject(response)
    }
  }
)

/**
 * get请求
 * @param {String} url 请求地址
 * @param {Object} params 请求参数
 */
export function get (url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err.data)
      })
  }).catch(function () { return Promise.resolve({ code: -1 }) })
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post (url, params) {
  return new Promise((resolve, reject) => {
    axios.defaults.headers['Content-Type'] = 'Content-Type: multipart/form-data'
    axios
      .post(url, params)
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err.data)
      })
  }).catch(function () { return Promise.resolve({ code: -1 }) })
}
export function jsonp (url, params) {
  return new Promise((resolve, reject) => {
    axios.defaults.headers['Content-Type'] = 'application/json'
    axios
      .post(url, params)
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err.data)
      })
  }).catch(function () { return Promise.resolve({ code: -1 }) })
}
export default axios
