// qt交互方法
import { getBrowser } from '@/utils/linkUtils'

/**
 * qt登录
 * 提供给悟空图像app调用的api
 * @param token  文件地址
 * @param pcversion 版本
 */
export function onLogin (token) {
}

/**
 * 功能切换(PC调用)
 */
export function menuChange (name) {
  // try {
  //   window.menuChangeForName(name)
  // } catch (e) {
  //   console.log(e)
  // }
}

/**
 * 获取PC图片(PC调用)
 */
export function imgInfoByPc (imgInfo) {
  try {
    window.setReferenceFromPc(imgInfo.type)
  } catch (e) {

  }
}

/**
 * 获取图片信息 (调用PC方法)
 * @param {*} paramName
 */
export const getPcImageInfo = async (paramName) => {
  return new Promise((resolve) => {
    try {
      const browser = getBrowser()
      if (browser.isCefBrowser) {
        window.PhotoSirExtension.getCanvasInfo(paramName, (err, returnData) => {
          console.log('cef-->pcAutoLoginDialog-->return:' + err + returnData)
          const imgData = JSON.parse(returnData)
          if (imgData && imgData.width) imgData.width = Math.round(imgData.width)
          if (imgData && imgData.height) imgData.height = Math.round(imgData.height)
          if (imgData && imgData.imageWidth) { imgData.imageWidth = Math.round(imgData.imageWidth) }
          if (imgData && imgData.imageHeight) { imgData.imageHeight = Math.round(imgData.imageHeight) }
          resolve(imgData)
        })
      } else {
        // qt方法调用
        let bridge
        /* eslint-disable no-new */
        /* eslint-disable no-undef */
        new QWebChannel(qt.webChannelTransport, function (channel) {
          bridge = channel.objects.bridge
          bridge.getCanvasInfo(paramName).then(function (data) {
            // alert("getCanvasInfo方法返回结果: " + data);
            const imgData = JSON.parse(data)
            if (imgData && imgData.width) imgData.width = Math.round(imgData.width)
            if (imgData && imgData.height) imgData.height = Math.round(imgData.height)
            if (imgData && imgData.imageWidth) { imgData.imageWidth = Math.round(imgData.imageWidth) }
            if (imgData && imgData.imageHeight) { imgData.imageHeight = Math.round(imgData.imageHeight) }
            resolve(imgData)
          })
        })
      }
    } catch (e) {
      resolve()
    }
  })
}
